import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Placeholder from "react-bootstrap/Placeholder";
import { ActivityUser, Expense, ExpenseFilter } from "../../model";
import { getLocale, shiftMonthRange } from "../../utils";
import { InlineIcon } from "../InlineIcon";
import { ArrowLeft, ArrowRight, Funnel } from "react-bootstrap-icons";
import { ExpenseListItem } from "./ExpenseListItem";

export interface ExpenseListProps {
  expenses: Expense[];
  isLoading: boolean;
  userById: (userId: string) => ActivityUser;
  onExpenseClick: (expenseId: string) => void;
  onFilterOpen: () => void;
  filter: ExpenseFilter;
  onFilterChange: (filter: ExpenseFilter) => void;
}

export const ExpenseList: React.FC<ExpenseListProps> = ({
  expenses,
  isLoading,
  userById,
  onExpenseClick,
  onFilterOpen,
  filter,
  onFilterChange,
}) => {
  const handleChangeRange = (direction: "before" | "after") => {
    const [startDate, endDate] = shiftMonthRange(
      filter.fromDate,
      filter.toDate,
      direction
    );
    onFilterChange({ ...filter, fromDate: startDate, toDate: endDate });
  };

  if (isLoading) {
    return (
      <>
        <ListGroup.Item className="d-flex flex-column align-items-stretch">
          <Placeholder as="p" animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex flex-column align-items-stretch">
          <Placeholder as="p" animation="glow">
            <Placeholder xs={8} />
          </Placeholder>
        </ListGroup.Item>
      </>
    );
  }

  return (
    <>
      <ListGroup.Item>
        <div className="d-flex flex-row justify-content-start align-items-center gap-3">
          <ButtonGroup>
            <Button
              variant="outline-primary"
              onClick={() => handleChangeRange("before")}
            >
              <InlineIcon icon={ArrowLeft} spaceAfter="no" />
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => handleChangeRange("after")}
            >
              <InlineIcon icon={ArrowRight} spaceAfter="no" />
            </Button>
            <Button variant="outline-primary" onClick={() => onFilterOpen()}>
              <InlineIcon icon={Funnel} spaceAfter="no" />
            </Button>
          </ButtonGroup>
          {new Intl.DateTimeFormat(getLocale(), {
            year: "2-digit",
            month: "short",
          }).formatRange(filter.fromDate, filter.toDate)}
        </div>
      </ListGroup.Item>
      {expenses.map((expense, index) => (
        <ExpenseListItem
          key={expense.id}
          expense={expense}
          previous={expenses[index - 1]}
          userById={userById}
          onClick={() => onExpenseClick(expense.id)}
        />
      ))}
    </>
  );
};
