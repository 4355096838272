import { Outlet } from "react-router-dom";
import { ProtectedRoute } from "./Authentication";
import { TopNavigation } from "./Navigation";
import { ActivitiesProvider, UserContext } from "../context";
import { useContext } from "react";
import { useDeleteRefreshToken } from "../hooks/api";

export const Home: React.FC = () => {
  const { unsetUser } = useContext(UserContext);
  const { mutateAsync: deleteRefreshToken, isPending } =
    useDeleteRefreshToken();

  const handleLogout = async () => {
    await deleteRefreshToken();
    unsetUser();
  };

  return (
    <ProtectedRoute loggingOut={isPending}>
      <div className="d-flex flex-column min-vh-100">
        <TopNavigation logout={handleLogout} />
        <div className="d-flex flex-grow-1 justify-content-center">
          <div className="col-xl-8 col-12 p-3">
            <ActivitiesProvider>
              <Outlet />
            </ActivitiesProvider>
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
};
