import Placeholder from "react-bootstrap/Placeholder";
import { useNavigate } from "react-router-dom";
import { EditActivityForm } from "./EditActivityForm";
import { navigation } from "../../../config";
import { useTranslation } from "../../../hooks";
import { useSaveActivity } from "../../../hooks/api";
import { Activity } from "../../../model";
import { ActivityBreadcrumbs } from "../../Navigation";

export interface EditActivityPageProps {
  activity: Activity | undefined;
  isNew?: boolean;
}

export const EditActivityPage: React.FC<EditActivityPageProps> = ({
  activity,
  isNew,
}) => {
  const { mutateAsync: saveActivity } = useSaveActivity();
  const { t } = useTranslation("Activities");
  const navigate = useNavigate();

  const handleSubmit = (updatedActivity: Activity) => {
    updatedActivity.id = updatedActivity.id || crypto.randomUUID();
    saveActivity(updatedActivity);
    navigate(navigation.expenses(updatedActivity.id));
  };

  return (
    <div className="d-flex flex-column gap-3">
      <ActivityBreadcrumbs
        activity={isNew ? undefined : activity}
        pageTitle={t(`Edit.${isNew ? "CreateActivity" : "EditActivity"}`)}
      />
      {!activity ? (
        <Placeholder as="p" animation="glow">
          <Placeholder xs={6} />
          <Placeholder xs={8} size="sm" />
        </Placeholder>
      ) : (
        <EditActivityForm
          isNew={isNew ?? false}
          activity={activity}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};
