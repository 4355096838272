import React from "react";
import { Icon } from "react-bootstrap-icons";

export type SpaceAfterIcon = "no" | "lg";

export interface InlineIconProps {
  icon: Icon;
  color?: string;
  spaceAfter: SpaceAfterIcon;
}

export const InlineIcon: React.FC<InlineIconProps> = (props) => (
  <props.icon
    size={20}
    color={props.color}
    style={{
      marginBottom: "3px",
      marginRight: spaceAfterIconPxValues[props.spaceAfter],
    }}
  />
);

const spaceAfterIconPxValues: Record<SpaceAfterIcon, string> = {
  no: "0px",
  lg: "8px",
};
