import { useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";

export const Wait: React.FC<{}> = () => {
  const [progress, setProgress] = useState(-10);

  setTimeout(() => {
    setProgress(progress + 5);
  }, 200);

  return (
    <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      {progress < 0 && <Spinner animation="border" variant="primary" />}
      {progress >= 0 && <ProgressBar now={progress} className="col-6" />}
    </div>
  );
};
