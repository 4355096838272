import { useContext, useMemo } from "react";
import { EditActivityPage } from "./EditActivityPage";
import { Activity } from "../../../model";
import { ActivityContext } from "../../../context";

export const EditActivity: React.FC<{}> = () => {
  const { activity } = useContext(ActivityContext);

  const editedActivity = useMemo<Activity | undefined>(() => {
    if (activity) {
      return {
        ...activity,
        users: activity.users.map((user) => ({ ...user })),
      };
    }
    return undefined;
  }, [activity]);

  return <EditActivityPage activity={editedActivity} />;
};
