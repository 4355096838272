import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import { getLocale } from "../../utils";
import { Calendar } from "./Calendar";
import { useTranslation } from "../../hooks";
import { InlineIcon } from "../InlineIcon";
import { Calendar as CalendarIcon, CalendarMinus } from "react-bootstrap-icons";

export interface DatePickerProps {
  value: Date;
  onChange: (newValue: Date) => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({ value, onChange }) => {
  const { t } = useTranslation("Common");
  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleYesterdayClick = () =>
    onChange(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1
      )
    );

  const handleDateChange = (newDate: Date) => {
    setCalendarOpen(false);
    onChange(newDate);
  };

  return (
    <>
      <ButtonGroup className="col-12">
        <Button
          variant="outline-primary"
          className="col-4"
          onClick={() => setCalendarOpen(true)}
        >
          <InlineIcon icon={CalendarIcon} spaceAfter="lg" />
          {new Intl.DateTimeFormat(getLocale()).format(new Date(value))}
        </Button>
        <Button
          variant="outline-secondary"
          className="col-4"
          onClick={handleYesterdayClick}
        >
          <InlineIcon icon={CalendarMinus} spaceAfter="lg" />
          {t("DatePicker.Yesterday")}
        </Button>
      </ButtonGroup>
      <Modal show={calendarOpen} onHide={() => setCalendarOpen(false)} centered>
        <Modal.Body>
          <Calendar date={value} onDateChange={handleDateChange} />
        </Modal.Body>
      </Modal>
    </>
  );
};
