import { Activity, ExportActivityRequest } from "../../model";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedFetch } from "./useAuthenticatedFetch";
import { EXPENSES_KEY, onOptimisticMutate } from "./";

export const ACTIVITIES_KEY = "activities";

export const useGetActivities = () => {
  const { GET } = useAuthenticatedFetch<Activity[]>();
  return useQuery<Activity[]>({
    queryKey: [ACTIVITIES_KEY],
    queryFn: () => GET("/activities"),
  });
};

export const useSaveActivity = () => {
  const queryClient = useQueryClient();
  const { POST } = useAuthenticatedFetch<Activity, Activity>();
  return useMutation({
    mutationFn: (data: Activity) => POST("/activities", { data }),
    ...onOptimisticMutate<Activity, Activity[]>(
      queryClient,
      [ACTIVITIES_KEY],
      (oldActivities, activity) => [
        ...(oldActivities ?? []).filter((a) => a.id !== activity.id),
        activity,
      ]
    ),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_KEY] });
    },
  });
};

export const useDeleteActivity = (activityId: string) => {
  const queryClient = useQueryClient();
  const { DELETE } = useAuthenticatedFetch<void>();
  return useMutation({
    mutationFn: () => DELETE(`/activities/${activityId}`),
    ...onOptimisticMutate<void, Activity[]>(
      queryClient,
      [ACTIVITIES_KEY],
      (oldActivities) =>
        oldActivities?.filter((activity) => activity.id !== activityId)
    ),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_KEY] });
    },
  });
};

export const useSetDefaultActivity = (activityId: string) => {
  const queryClient = useQueryClient();
  const { POST } = useAuthenticatedFetch<void, { isDefault: boolean }>();
  return useMutation({
    mutationFn: (isDefault: boolean) =>
      POST(`/activities/${activityId}/set-default`, { data: { isDefault } }),
    ...onOptimisticMutate<boolean, Activity[]>(
      queryClient,
      [ACTIVITIES_KEY],
      (oldActivities, isDefault) =>
        oldActivities?.map((a) => ({
          ...a,
          isDefault: a.id === activityId && isDefault,
        }))
    ),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_KEY] });
    },
  });
};

export const useExportActivity = (
  activityId: string,
  { exportTo, overrideExisting }: ExportActivityRequest
) => {
  const { POST } = useAuthenticatedFetch<void, ExportActivityRequest>();
  return useMutation({
    mutationFn: () =>
      POST(`/activities/${activityId}/export`, {
        data: { exportTo, overrideExisting },
      }),
  });
};

export const useImportActivity = (activityId: string, importFrom: string) => {
  const queryClient = useQueryClient();
  const { POST } = useAuthenticatedFetch<void, { importFrom: string }>();
  return useMutation({
    mutationFn: () =>
      POST(`/activities/${activityId}/import`, {
        data: { importFrom },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_KEY] });
      queryClient.invalidateQueries({ queryKey: [EXPENSES_KEY, activityId] });
    },
  });
};
