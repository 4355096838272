import { useCallback } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { AuthProvider, authProvidersConfig } from "../../config";
import { authorize } from "../../services";
import { useTranslation } from "../../hooks";
import { Coin, Facebook, Google, Microsoft } from "react-bootstrap-icons";

export interface LoginProps {
  isError: boolean;
}

export const Login: React.FC<LoginProps> = ({ isError }) => {
  const { t } = useTranslation("Authentication");

  const loginMicrosoft = useCallback(
    () => authorize(authProvidersConfig[AuthProvider.MICROSOFT]),
    []
  );
  const loginGoogle = useCallback(
    () => authorize(authProvidersConfig[AuthProvider.GOOGLE]),
    []
  );
  const loginFacebook = useCallback(
    () => authorize(authProvidersConfig[AuthProvider.FACEBOOK]),
    []
  );

  const SignInButton = ({
    label,
    icon,
    onClick,
  }: {
    label: string;
    icon: JSX.Element;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  }) => (
    <Button
      className="col-xl-3 col-lg-4 col-md-6 col-sm-8 col-10"
      size="lg"
      onClick={onClick}
    >
      {label} {icon}
    </Button>
  );

  const errorAlert = (
    <Alert
      variant="danger"
      className="col-xl-3 col-lg-4 col-md-6 col-sm-8 col-10"
    >
      <Alert.Heading>{t("PostLogin.Error.Title")}</Alert.Heading>
      {t("PostLogin.Error.Description")}
    </Alert>
  );

  return (
    <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <h2>
        <Coin size={20} style={{ marginBottom: "3px" }} />{" "}
        {t("Login.ApplicationTitle")}
      </h2>
      <p></p>
      {isError && errorAlert}
      <SignInButton
        label={t("Login.SignInMicrosoft")}
        icon={<Microsoft style={{ marginBottom: "3px", marginLeft: "2px" }} />}
        onClick={loginMicrosoft}
      />
      <p></p>
      <SignInButton
        label={t("Login.SignInGoogle")}
        icon={<Google style={{ marginBottom: "3px", marginLeft: "2px" }} />}
        onClick={loginGoogle}
      />
      <p></p>
      <SignInButton
        label={t("Login.SignInFacebook")}
        icon={<Facebook style={{ marginBottom: "3px", marginLeft: "2px" }} />}
        onClick={loginFacebook}
      />
    </div>
  );
};
