import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useTranslation } from "../../hooks";

export interface ExportProgressProps {
  type: "Export" | "Import";
  inProgress: boolean;
}

export const ExportProgress: React.FC<ExportProgressProps> = ({
  type,
  inProgress,
}) => {
  const { t } = useTranslation("Export");

  return (
    <Modal show={inProgress} backdrop="static" centered>
      <Modal.Header>
        <Modal.Title>{t(`${type}ing`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressBar animated now={100} />
      </Modal.Body>
    </Modal>
  );
};
