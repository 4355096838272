import React, { useContext, useState } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { ExpenseFilterPane, ExpenseList, Totals } from "./";
import { useExpenseFilter, useTranslation } from "../../hooks";
import { ActivityContext } from "../../context";
import { ActivityUser, Expense, ExpenseFilter } from "../../model";

export interface ExpensesPanelProps {
  expenses: Expense[];
  isLoading: boolean;
  userById: (userId: string) => ActivityUser;
  onExpenseClick: (expenseId: string) => void;
}

export const ExpensesPanel: React.FC<ExpensesPanelProps> = ({
  expenses,
  isLoading,
  userById,
  onExpenseClick,
}) => {
  const { activity } = useContext(ActivityContext);
  const { t } = useTranslation("Expenses");

  const { filter, setFilter, filteredExpenses } = useExpenseFilter(
    activity,
    expenses
  );

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const handleFilterChange = (newFilter: ExpenseFilter) => {
    setFilter(newFilter);
    setIsFilterOpen(false);
  };

  return (
    <Card>
      <Card.Header>{t("Title")}</Card.Header>
      <ListGroup variant="flush">
        <ExpenseList
          expenses={filteredExpenses}
          isLoading={isLoading}
          userById={userById}
          onExpenseClick={onExpenseClick}
          onFilterOpen={() => setIsFilterOpen(true)}
          filter={filter}
          onFilterChange={handleFilterChange}
        />
        <Totals
          isLoading={isLoading}
          activity={activity}
          expenses={filteredExpenses}
          userById={userById}
        />
      </ListGroup>
      <ExpenseFilterPane
        open={isFilterOpen}
        filter={filter}
        onSubmit={handleFilterChange}
        onCancel={() => setIsFilterOpen(false)}
      />
    </Card>
  );
};
