import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../../hooks";
import { useDeleteExpense, useSaveExpense } from "../../../hooks/api";
import { Activity, Expense } from "../../../model";
import { EditExpenseForm } from "./EditExpenseForm";
import { navigation } from "../../../config";
import { ActivityBreadcrumbs } from "../../Navigation";

export interface EditExpensePageProps {
  activityId: string;
  activity: Activity | undefined;
  expense: Expense | undefined;
  isNew?: boolean;
}

export const EditExpensePage: React.FC<EditExpensePageProps> = ({
  activityId,
  activity,
  expense,
  isNew,
}) => {
  const { mutateAsync: saveExpense } = useSaveExpense(activityId);
  const { mutateAsync: deleteExpense } = useDeleteExpense(activityId);
  const navigate = useNavigate();
  const { t } = useTranslation("Expenses");

  const handleSubmit = (expense: Expense) => {
    saveExpense(expense);
    navigate(navigation.expenses(activityId));
  };

  const handleDelete = (expense: Expense) => {
    deleteExpense(expense.id);
    navigate(navigation.expenses(activityId));
  };

  return (
    <div className="d-flex flex-column gap-3">
      <ActivityBreadcrumbs
        activity={activity}
        pageTitle={t(`Edit.${isNew ? "CreateExpense" : "EditExpense"}`)}
      />
      {!expense ? (
        <Placeholder as="p" animation="glow">
          <Placeholder xs={6} />
          <Placeholder xs={8} size="sm" />
        </Placeholder>
      ) : (
        <EditExpenseForm
          isNew={isNew}
          users={activity?.users ?? []}
          expense={expense}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};
