import { useAuthenticatedFetch } from "./useAuthenticatedFetch";
import { useMutation } from "@tanstack/react-query";

export const useMergeUser = () => {
  const { POST } = useAuthenticatedFetch<void, { guestUserId: string }>();
  return useMutation({
    mutationFn: (guestUserId: string) =>
      POST("/user/merge", { data: { guestUserId } }),
  });
};
