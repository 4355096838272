import { useContext } from "react";
import { UserContext } from "../../context";
import { User } from "../../model";
import { ErrorType, HttpMethod, QueryOptions, fetch } from "../../services";
import { reAuthenticate } from "./useAuthApi";

let globalReAuthenticate: Promise<User> | undefined = undefined;

export const useAuthenticatedFetch = <TResponse, TData = void>() => {
  const { user, setUser } = useContext(UserContext);

  const reAuthenticateAndRetry = async (
    route: string,
    method: HttpMethod,
    options: QueryOptions<TData>
  ): Promise<TResponse> => {
    try {
      // awaiting global reAuthenticate function promise, to avoid concurrent call problems
      if (!globalReAuthenticate) {
        globalReAuthenticate = reAuthenticate();
      }
      const user = await globalReAuthenticate;
      setUser(user);
      return await fetch(route, method, {
        ...options,
        accessToken: user.accessToken,
      });
    } finally {
      globalReAuthenticate = undefined;
    }
  };

  const fetcher = async (
    route: string,
    method: HttpMethod,
    options: QueryOptions<TData>
  ): Promise<TResponse> => {
    try {
      return await fetch(route, method, {
        ...options,
        accessToken: user?.accessToken,
      });
    } catch (error: any) {
      if (error.message === ErrorType.unauthorized) {
        return reAuthenticateAndRetry(route, method, options);
      } else {
        throw error;
      }
    }
  };

  return {
    GET: (route: string, options?: Pick<QueryOptions<void>, "apiVersion">) =>
      fetcher(route, HttpMethod.Get, options ?? {}),
    POST: (
      route: string,
      options: Pick<QueryOptions<TData>, "apiVersion" | "data">
    ) => fetcher(route, HttpMethod.Post, options),
    DELETE: (route: string, options?: Pick<QueryOptions<void>, "apiVersion">) =>
      fetcher(route, HttpMethod.Delete, options ?? {}),
  };
};
