export const routeSlugs = {
  activity: ":activityId",
  expense: ":expenseId",
};

export const routesConfig = {
  postLogin: {
    microsoft: "/auth_callback/microsoft",
    google: "/auth_callback/google",
    facebook: "/auth_callback/facebook",
  },
  home: "/",
  activities: "activities",
  newActivity: "activities/new",
  activity: `activities/${routeSlugs.activity}`,
  expenses: "expenses",
  expense: `expenses/${routeSlugs.expense}`,
  newExpense: "expenses/new",
  export: "export",
  all: "*",
};

export const navigation = {
  activities: `${routesConfig.home}${routesConfig.activities}`,
  activity: (activityId: string) =>
    `${routesConfig.home}${routesConfig.activity}`.replace(
      routeSlugs.activity,
      activityId
    ),
  newActivity: `${routesConfig.home}${routesConfig.newActivity}`,
  expenses: (activityId: string) =>
    `${routesConfig.home}${routesConfig.activity}/${routesConfig.expenses}`.replace(
      routeSlugs.activity,
      activityId
    ),
  expense: (activityId: string, expenseId: string) =>
    `${routesConfig.home}${routesConfig.activity}/${routesConfig.expense}`
      .replace(routeSlugs.activity, activityId)
      .replace(routeSlugs.expense, expenseId),
  newExpense: (activityId: string) =>
    `${routesConfig.home}${routesConfig.activity}/${routesConfig.newExpense}`.replace(
      routeSlugs.activity,
      activityId
    ),
  export: (activityId: string) =>
    `${routesConfig.home}${routesConfig.activity}/${routesConfig.export}`.replace(
      routeSlugs.activity,
      activityId
    ),
};
