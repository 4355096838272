import { useMemo } from "react";
import { firstDayOfWeek, getLocale } from "../../utils";

const DAYS_IN_WEEK = 7;
const MAX_WEEKS_IN_MONTH = 6;

export const useCalendarDates = (date: Date) => {
  const dateTime = date.getTime();
  const monthDates = useMemo(() => {
    const refDate = new Date(dateTime);

    const startDate = firstDayOfWeek(
      new Date(refDate.getFullYear(), refDate.getMonth())
    );

    return Array.from(Array(MAX_WEEKS_IN_MONTH).keys()).map((week) =>
      Array.from(Array(DAYS_IN_WEEK).keys()).map(
        (day) =>
          new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate() + week * DAYS_IN_WEEK + day
          )
      )
    );
  }, [dateTime]);

  const daysOfWeek = useMemo(
    () =>
      monthDates[0].map((d) =>
        new Intl.DateTimeFormat(getLocale(), { weekday: "narrow" }).format(d)
      ),
    [monthDates]
  );

  const monthsNames = useMemo(() => {
    return Array.from(Array(12).keys()).map((month) =>
      new Intl.DateTimeFormat(getLocale(), {
        month: "long",
      }).format(new Date(new Date().getFullYear(), month))
    );
  }, []);

  return { monthDates, daysOfWeek, monthsNames, areSameDate, areSameMonth };
};

const areSameDate = (a: Date, b: Date) =>
  a.getFullYear() === b.getFullYear() &&
  a.getMonth() === b.getMonth() &&
  a.getDate() === b.getDate();

const areSameMonth = (a: Date, b: Date) =>
  a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth();
