import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import { InlineIcon } from "../InlineIcon";
import { useCalendarDates } from "./useCalendarDates";

export interface CalendarProps {
  date: Date;
  onDateChange: (newDate: Date) => void;
}

export const Calendar: React.FC<CalendarProps> = ({ date, onDateChange }) => {
  const [currentMonth, setCurrentMonth] = useState(
    new Date(date.getFullYear(), date.getMonth())
  );

  const { monthDates, daysOfWeek, monthsNames, areSameDate, areSameMonth } =
    useCalendarDates(currentMonth);

  const handlePreviousMonthClick = () =>
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1)
    );

  const handleNextMonthClick = () =>
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1)
    );

  const handleMonthSelect = (value: string) =>
    setCurrentMonth(new Date(currentMonth.getFullYear(), parseInt(value, 10)));

  const handleYearChange = (value: string) =>
    setCurrentMonth(new Date(parseInt(value, 10), currentMonth.getMonth()));

  return (
    <>
      <div className="mb-3 col-12 col-md-10 offset-md-1">
        <InputGroup>
          <Button variant="outline" onClick={handlePreviousMonthClick}>
            <InlineIcon icon={ArrowLeft} spaceAfter="no" />
          </Button>
          <Form.Select
            value={currentMonth.getMonth()}
            onChange={({ target }) => handleMonthSelect(target.value)}
            className="fw-bold"
          >
            {monthsNames.map((month, monthIndex) => (
              <option key={monthIndex} value={monthIndex}>
                {month}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            type="text"
            inputMode="numeric"
            className="fw-bold"
            value={currentMonth.getFullYear()}
            onChange={({ target }) => handleYearChange(target.value)}
          ></Form.Control>
          <Button variant="outline" onClick={handleNextMonthClick}>
            <InlineIcon icon={ArrowRight} spaceAfter="no" />
          </Button>
        </InputGroup>
      </div>
      <div>
        <Table bordered={false} borderless={true} className="m-0">
          <thead>
            <tr>
              {daysOfWeek.map((day, index) => (
                <th key={`${day}${index}`} className="px-0 pt-0 pb-1">
                  <div className="d-flex justify-content-center">{day}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {monthDates.map((week) => (
              <tr key={week[0].getTime()}>
                {week.map((day) => (
                  <td key={day.getTime()} className="px-0 py-1">
                    <div className="d-flex justify-content-center">
                      <Button
                        variant={
                          areSameDate(day, date)
                            ? "primary"
                            : areSameDate(day, new Date())
                            ? "outline-primary"
                            : "outline"
                        }
                        onClick={() => onDateChange(day)}
                        className={
                          areSameMonth(day, currentMonth) ||
                          areSameDate(day, date)
                            ? ""
                            : "text-black-50"
                        }
                      >
                        {day.getDate()}
                      </Button>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};
