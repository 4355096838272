import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "../hooks";

export interface ConfirmationModalProps {
  title: string;
  description: string;
  okLabel?: string;
  open: boolean;
  onClose: (confirm: boolean) => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  description,
  okLabel,
  open,
  onClose,
}) => {
  const { t } = useTranslation("Common");

  return (
    <Modal show={open} onHide={() => onClose(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose(false)}>
          {t("ConfirmationModal.Cancel")}
        </Button>
        <Button variant="primary" onClick={() => onClose(true)}>
          {okLabel ?? t("ConfirmationModal.Ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
