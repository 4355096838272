import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from "@tanstack/react-query";
import { Home } from "./";
import { Activities, CreateActivity, EditActivity } from "./Activities";
import { PostLogin } from "./Authentication";
import { CreateExpense, EditExpense, Expenses } from "./Expenses";
import { Export } from "./Export";
import { AuthProvider, routesConfig } from "../config";
import { ActivityProvider, UserProvider } from "../context";
import { getLocaleLanguage } from "../utils";

const router = createBrowserRouter([
  {
    path: routesConfig.all,
    element: <Navigate to={routesConfig.home} replace />,
  },
  {
    path: routesConfig.postLogin.microsoft,
    element: <PostLogin authProvider={AuthProvider.MICROSOFT} />,
  },
  {
    path: routesConfig.postLogin.google,
    element: <PostLogin authProvider={AuthProvider.GOOGLE} />,
  },
  {
    path: routesConfig.postLogin.facebook,
    element: <PostLogin authProvider={AuthProvider.FACEBOOK} />,
  },
  {
    path: routesConfig.home,
    element: <Home />,
    children: [
      {
        path: "",
        element: <Navigate to={routesConfig.activities} replace />,
      },
      {
        path: routesConfig.activities,
        element: <Activities />,
      },
      {
        path: routesConfig.newActivity,
        element: <CreateActivity />,
      },
      {
        path: routesConfig.activity,
        element: (
          <ActivityProvider>
            <Outlet />
          </ActivityProvider>
        ),
        children: [
          {
            path: "",
            element: <EditActivity />,
          },
          {
            path: routesConfig.expenses,
            element: <Expenses />,
          },
          {
            path: routesConfig.expense,
            element: <EditExpense />,
          },
          {
            path: routesConfig.newExpense,
            element: <CreateExpense />,
          },
          {
            path: routesConfig.export,
            element: <Export />,
          },
        ],
      },
    ],
  },
]);

export const Main: React.FC = () => {
  const queryClient = useQueryClient(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: Infinity,
          retry: false,
        },
      },
    })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <div lang={getLocaleLanguage()}>
          <RouterProvider router={router} />
        </div>
      </UserProvider>
    </QueryClientProvider>
  );
};
