import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthProvider, routesConfig } from "../../config";
import { UserContext } from "../../context";
import { useGetAuthenticatedUser } from "../../hooks/api";
import { Login } from "./Login";
import { Wait } from "./Wait";

export interface PostLoginProps {
  authProvider: AuthProvider;
}

export const PostLogin: React.FC<PostLoginProps> = ({ authProvider }) => {
  const { setUser } = useContext(UserContext);
  const {
    data: authenticatedUser,
    isSuccess,
    isError,
  } = useGetAuthenticatedUser(authProvider);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && authenticatedUser) {
      setUser(authenticatedUser);
      navigate(routesConfig.home);
    }
  }, [isSuccess, authenticatedUser, setUser, navigate]);

  if (isError) {
    return <Login isError={true} />;
  }

  return <Wait />;
};
