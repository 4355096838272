import { currencyByCode } from "./currencyUtils";
import { getLocale } from "./localeUtils";

// 100 => $1
export const toMajorCurrencyUnit = (
  amountInMinorUnit: number,
  currencyCode: string
) => amountInMinorUnit / Math.pow(10, currencyByCode[currencyCode].minorUnits);

// $1 => 100
export const toMinorCurrencyUnit = (
  amountInMajorUnit: number,
  currencyCode: string
) =>
  Math.round(
    amountInMajorUnit * Math.pow(10, currencyByCode[currencyCode].minorUnits)
  );

export const sanitizeAmountValue = (value: string) =>
  value.replaceAll(/[^0-9.,\- ]/g, "").replaceAll(/(.+)-/g, "$1");

export const formatAmountValue = (value: number) =>
  value
    ? new Intl.NumberFormat(getLocale(), {
        // @ts-expect-error: open issue https://github.com/microsoft/TypeScript/issues/52072
        numberingSystem: "latn",
        useGrouping: false,
      }).format(value)
    : "";

export const parseAmountValue = (value: string) => {
  value = sanitizeAmountValue(value).replaceAll(" ", "");
  const split = value.split(/[.,]/);
  return split.length === 1
    ? parseInt(value)
    : parseFloat(
        `${split.slice(0, split.length - 1).join("")}.${
          split[split.length - 1]
        }`
      );
};
