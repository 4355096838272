import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { navigation } from "../../config";
import { useTranslation, useUser } from "../../hooks";
import { Coin, BoxArrowRight } from "react-bootstrap-icons";
import { InlineIcon } from "../InlineIcon";

export interface TopNavigationProps {
  logout: () => void;
}

export const TopNavigation: React.FC<TopNavigationProps> = ({ logout }) => {
  const { t } = useTranslation("Navigation");
  const user = useUser();

  return (
    <Navbar expand="sm" bg="primary" variant="dark">
      <Container>
        <Navbar.Brand as={Link} to={navigation.activities}>
          <InlineIcon icon={Coin} color="white" spaceAfter="lg" />
          {t("ApplicationTitle")}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="me-auto" />
          <Nav>
            <NavDropdown title={user.displayName} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={logout}>
                <InlineIcon icon={BoxArrowRight} spaceAfter="lg" />
                {t("SignOut")}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
