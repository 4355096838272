import { monthDiff } from "./dateUtils";

export const DEFAULT_MONTH_RANGE = 3;

export const getMonthRangeEnd = (date?: Date) => {
  const dateCopy = date ? new Date(date) : new Date();
  return new Date(
    dateCopy.getFullYear(),
    dateCopy.getMonth() + 1,
    1,
    0,
    0,
    0,
    -1
  );
};

export const getMonthRangeStart = (endDate: Date, date?: Date) => {
  const defaultStartDate = new Date(
    endDate.getFullYear(),
    endDate.getMonth() - DEFAULT_MONTH_RANGE + 1 // range end is inclusive
  );

  if (!date) {
    return defaultStartDate;
  }

  const dateCopy = new Date(date);
  const actualStartDate = new Date(dateCopy.getFullYear(), dateCopy.getMonth());
  return actualStartDate < defaultStartDate
    ? defaultStartDate
    : actualStartDate;
};

export const shiftMonthRange = (
  startDate: Date,
  endDate: Date,
  direction: "before" | "after"
): [Date, Date] => {
  var offset =
    (monthDiff(startDate, endDate) + 1) * (direction === "after" ? 1 : -1);

  const newStartDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + offset
  );
  const newEndDate = new Date(
    endDate.getFullYear(),
    endDate.getMonth() + offset + 1,
    1,
    0,
    0,
    0,
    -1
  );

  return [newStartDate, newEndDate];
};
