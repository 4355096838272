import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useCalendarDates } from "./useCalendarDates";

export interface MonthInputProps {
  date: Date;
  onChange: (date: Date) => void;
}

export const MonthInput: React.FC<MonthInputProps> = ({ date, onChange }) => {
  const { monthsNames } = useCalendarDates(date);

  const handleMonthChange = (event: { target: { value: string } }) =>
    onChange(
      new Date(
        date.getFullYear(),
        parseInt(event.target.value, 10),
        date.getDate()
      )
    );

  const handleYearChange = (event: { target: { value: string } }) =>
    onChange(
      new Date(
        parseInt(event.target.value, 10),
        date.getMonth(),
        date.getDate()
      )
    );

  return (
    <InputGroup>
      <Form.Select value={date.getMonth()} onChange={handleMonthChange}>
        {monthsNames.map((month, monthIndex) => (
          <option key={monthIndex} value={monthIndex}>
            {month}
          </option>
        ))}
      </Form.Select>
      <Form.Control
        type="text"
        inputMode="numeric"
        value={date.getFullYear()}
        onChange={handleYearChange}
      ></Form.Control>
    </InputGroup>
  );
};
