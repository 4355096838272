import { QueryClient, QueryKey } from "@tanstack/react-query";

export const onOptimisticMutate = <TData, TCacheData>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  updater: (cacheData: TCacheData, data: TData) => TCacheData
) => ({
  onMutate: (data: TData) => {
    queryClient.cancelQueries({ queryKey });
    const dataSnapshot = queryClient.getQueryData<TCacheData>(queryKey);
    queryClient.setQueryData<TCacheData>(queryKey, (cacheData) =>
      updater(cacheData as TCacheData, data)
    );
    return { data: dataSnapshot };
  },
  onError: (
    _error: Error,
    _data: TData,
    context: { data: TCacheData | undefined } | undefined
  ) => {
    queryClient.setQueryData<TCacheData>(queryKey, context?.data);
  },
});
