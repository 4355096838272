import currencies from "./currencies.json";
import { getLocales } from "./localeUtils";

const DEFAULT_REGION = "US";

export type Currency = {
  code: string;
  name: string;
  countryCodes: string[];
  minorUnits: number;
};

const entryToCurrency = (
  entry: [string, { name: string; countries: string[]; minorUnits: string }]
): Currency => ({
  code: entry[0],
  countryCodes: entry[1].countries,
  minorUnits: parseInt(entry[1].minorUnits, 10),
  name: entry[1].name,
});

const currencyByCountry: Record<string, Currency> = Object.entries(
  currencies
).reduce((acc: Record<string, Currency>, entry) => {
  entry[1].countries.forEach(
    (countryCode) => (acc[countryCode] = entryToCurrency(entry))
  );
  return acc;
}, {});

const getUserRegion = () => {
  try {
    return (
      getLocales()
        .map((language) => new Intl.Locale(language).region)
        .filter((region) => region)[0] ?? DEFAULT_REGION
    );
  } catch (error) {
    return DEFAULT_REGION;
  }
};

export const currencyByCode: Record<string, Currency> = Object.entries(
  currencies
).reduce((acc, entry) => ({ ...acc, [entry[0]]: entryToCurrency(entry) }), {});

export const currencyList: Currency[] = Object.entries(currencies)
  .map(entryToCurrency)
  .sort((a, b) => a.code.localeCompare(b.code));

export const getDefaultCurrency = () => currencyByCountry[getUserRegion()];
