import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { navigation } from "../../config";
import { useTranslation } from "../../hooks";
import { ActivityList } from "./";
import { InlineIcon } from "../InlineIcon";
import { PlusLg } from "react-bootstrap-icons";
import { useContext } from "react";
import { ActivitiesContext } from "../../context";

export const Activities: React.FC<{}> = () => {
  const { activities, isLoadingActivities } = useContext(ActivitiesContext);
  const navigate = useNavigate();
  const { t } = useTranslation("Activities");

  const createActivity = () => navigate(navigation.newActivity);

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-row gap-3 justify-content-end">
        <Button onClick={createActivity} disabled={isLoadingActivities}>
          <InlineIcon icon={PlusLg} spaceAfter="lg" />
          {t("Actions.CreateActivity")}
        </Button>
      </div>
      <ActivityList
        activities={activities ?? []}
        isLoading={isLoadingActivities}
      />
    </div>
  );
};
