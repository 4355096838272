import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthProvider, authProvidersConfig } from "../../config";
import {
  ErrorType,
  HttpMethod,
  fetch,
  getAuthenticatedUser,
} from "../../services";
import { User } from "../../model";

const AUTH_USER_KEY = "auth-user";

const REAUTH_KEY = "reAuth";

export const useGetAuthenticatedUser = (authProvider: AuthProvider) => {
  const config = authProvidersConfig[authProvider];
  return useQuery({
    queryKey: [AUTH_USER_KEY],
    queryFn: async () => {
      const user = await getAuthenticatedUser(config);
      localStorage.setItem(REAUTH_KEY, "true");
      return user;
    },
  });
};

export const reAuthenticate = (): Promise<User> =>
  fetch("/auth", HttpMethod.Get, {});

export const useReAuthenticate = (enabled: boolean) => {
  return useQuery({
    queryKey: [AUTH_USER_KEY],
    queryFn: reAuthenticate,
    enabled,
    retry: (failureCount, error) => {
      const reAuth = localStorage.getItem(REAUTH_KEY) === "true";
      return (
        reAuth && failureCount < 1 && error.message === ErrorType.forbidden
      );
    },
  });
};

export const useDeleteRefreshToken = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => fetch("/auth", HttpMethod.Delete, {}),
    onMutate: () => {
      localStorage.setItem(REAUTH_KEY, "false");
      queryClient.removeQueries({ queryKey: [AUTH_USER_KEY] });
    },
  });
};
