export enum HttpMethod {
  Get = "GET",
  Post = "POST",
  Delete = "DELETE",
}

enum HttpStatusCode {
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
}

export enum ErrorType {
  unknown = "unknown",
  unauthorized = "unauthorized",
  forbidden = "forbidden",
  notFound = "notFound",
}

const errorTypes: Record<number, ErrorType> = {
  [HttpStatusCode.unauthorized]: ErrorType.unauthorized,
  [HttpStatusCode.forbidden]: ErrorType.forbidden,
  [HttpStatusCode.notFound]: ErrorType.notFound,
};

export interface QueryOptions<TData> {
  accessToken?: string;
  data?: TData;
  apiVersion?: number;
}

export const fetch = async <TData>(
  route: string,
  method: HttpMethod = HttpMethod.Get,
  options: QueryOptions<TData>
) => {
  const { accessToken, apiVersion, data } = options;

  const headers: Headers = new Headers({
    "Content-Type": "application/json",
  });

  if (accessToken) {
    headers.append("Authorization", `Bearer ${accessToken}`);
  }

  const response: Response = await window.fetch(
    `/api/v${apiVersion ?? 1}${route}`,
    {
      method,
      headers,
      body: !!data ? JSON.stringify(data) : undefined,
    }
  );

  if (!response.ok) {
    throw new Error(errorTypes[response.status] ?? ErrorType.unknown);
  }
  try {
    return await response.json();
  } catch {
    return;
  }
};
