import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Placeholder from "react-bootstrap/Placeholder";
import { useAmountFormatter, useTranslation } from "../../hooks";
import { Activity, ActivityUser, Expense } from "../../model";
import { useMemo } from "react";
import { Calculator } from "react-bootstrap-icons";

export interface TotalsProps {
  isLoading: boolean;
  activity: Activity | undefined;
  expenses: Expense[];
  userById: (userId: string) => ActivityUser;
}

export const Totals: React.FC<TotalsProps> = ({
  isLoading,
  activity,
  expenses,
  userById,
}) => {
  const { t } = useTranslation("Expenses");
  const { formatAmount } = useAmountFormatter(activity?.currency);

  const total = useMemo(
    () =>
      expenses.reduce(
        (acc: number, expense: Expense) => acc + expense.amount,
        0
      ),
    [expenses]
  );

  const userTotalById = useMemo(
    () =>
      expenses.reduce<Record<string, ActivityUser>>((acc, expense) => {
        const { userId, amount } = expense;
        const user = acc[userId] ?? { ...userById(userId), totalExpenses: 0 };
        return {
          ...acc,
          [userId]: {
            ...user,
            totalExpenses: (user.totalExpenses ?? 0) + amount,
          },
        };
      }, {}),
    [expenses, userById]
  );

  if (isLoading || !activity) {
    return (
      <Card>
        <Card.Header>{t("TotalsCard.Title")}</Card.Header>
        <Card.Body>
          <Placeholder as="p" animation="glow">
            <Placeholder xs={5} />
          </Placeholder>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      {total > 0 && (
        <>
          <ListGroup.Item className="d-flex flex-row justify-content-center pt-3 pb-3 bg-light">
            <Calculator size={20} />
          </ListGroup.Item>
          <ListGroup.Item className="d-flex flex-row justify-content-stretch gap-2 align-items-center">
            <span className="flex-grow-1"></span>
            <span>{formatAmount(total)}</span>
          </ListGroup.Item>
        </>
      )}
      {Object.values(userTotalById).map((activityUser, index) => (
        <ListGroup.Item
          key={activityUser.userId ?? index}
          className="d-flex flex-row justify-content-stretch gap-2 align-items-center"
        >
          <span className="flex-grow-1">{activityUser.displayName}</span>
          <span>{formatAmount(activityUser.totalExpenses ?? 0)}</span>
        </ListGroup.Item>
      ))}
    </>
  );
};
