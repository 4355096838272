import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { ActivityUser, Expense } from "../../model";
import { getLocale, isSameMonth, toMajorCurrencyUnit } from "../../utils";
import { DashLg } from "react-bootstrap-icons";

export interface ExpenseListItemProps {
  expense: Expense;
  previous: Expense | undefined;
  onClick: (expense: Expense) => void;
  userById: (userId: string) => ActivityUser;
}

export const ExpenseListItem: React.FC<ExpenseListItemProps> = ({
  expense,
  previous,
  onClick,
  userById,
}) => {
  const addSpacer =
    previous && !isSameMonth(new Date(previous.date), new Date(expense.date));

  return (
    <>
      {addSpacer && (
        <ListGroup.Item className="d-flex flex-row justify-content-center pt-3 pb-3 bg-light">
          <DashLg size={20} />
        </ListGroup.Item>
      )}
      <ListGroup.Item
        action
        className="d-flex flex-row flex-wrap"
        onClick={() => onClick(expense)}
      >
        <div className="col-md-3 col-6">{expense.description}</div>
        <div className="col-md-3 col-6">
          {new Intl.NumberFormat(getLocale(), {
            style: "currency",
            currency: expense.currency,
          }).format(toMajorCurrencyUnit(expense.amount, expense.currency))}
        </div>
        <div className="col-md-3 col-6">
          <small className="text-muted">
            {userById(expense.userId)?.displayName}
          </small>
        </div>
        <div className="col-md-3 col-6">
          <small className="text-muted">
            {new Intl.DateTimeFormat(getLocale()).format(
              new Date(expense.date)
            )}
          </small>
        </div>
      </ListGroup.Item>
    </>
  );
};
