import { routesConfig } from "./routesConfig";
import { OAuth2Config } from "../services";
import { combinePath } from "../utils";

export enum AuthProvider {
  MICROSOFT = "Microsoft",
  GOOGLE = "Google",
  FACEBOOK = "Facebook",
}

const getRedirectUri = (postLoginRoute: string) =>
  combinePath(new URL(window.location.href).origin, postLoginRoute);

const getAuthenticateEndpoint = (authProvider: AuthProvider) =>
  `/auth/${authProvider.toLowerCase()}`;

// TODO move { authorizeUrl, clientId, scope } config below
// to api (not necessarily all in appConfig/env vars)
// and fetch it in Login page

const microsoftConfig: OAuth2Config = {
  authorizeUrl:
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
  clientId: "2754fd54-e0e3-4d20-bbfb-36e1800785b7",
  redirectUri: getRedirectUri(routesConfig.postLogin.microsoft),
  scope: ["offline_access", "User.Read"],
  authenticateEndpoint: getAuthenticateEndpoint(AuthProvider.MICROSOFT),
};

const googleConfig: OAuth2Config = {
  authorizeUrl: "https://accounts.google.com/o/oauth2/v2/auth",
  clientId:
    "712385669029-hqp4bmgl1neq7vok5h0gd46s004ejcsl.apps.googleusercontent.com",
  redirectUri: getRedirectUri(routesConfig.postLogin.google),
  scope: [
    // "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
    // TODO only add below if required - present in this user.features[]
    "https://www.googleapis.com/auth/spreadsheets", // TODO if Feature.Export + Import
  ],
  authenticateEndpoint: getAuthenticateEndpoint(AuthProvider.GOOGLE),
};

const facebookConfig: OAuth2Config = {
  authorizeUrl: "https://www.facebook.com/v18.0/dialog/oauth",
  clientId: "818962439968665",
  redirectUri: getRedirectUri(routesConfig.postLogin.facebook),
  scope: [],
  authenticateEndpoint: getAuthenticateEndpoint(AuthProvider.FACEBOOK),
};

export const authProvidersConfig: Record<AuthProvider, OAuth2Config> = {
  [AuthProvider.MICROSOFT]: microsoftConfig,
  [AuthProvider.GOOGLE]: googleConfig,
  [AuthProvider.FACEBOOK]: facebookConfig,
};
