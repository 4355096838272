import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { getLocale } from "./localeUtils";

const localeImports = {
  fr: () => import("dayjs/locale/fr"),
};

const initDayJs = async () => {
  const locale = getLocale();
  const language = new Intl.Locale(locale)
    .language as keyof typeof localeImports;
  if (localeImports[language]) {
    await localeImports[language]();
    dayjs.locale(locale);
  }
  dayjs.extend(relativeTime);
  dayjs.extend(localeData);
  dayjs.extend(weekday);
};

initDayJs();

export const ago = (date: Date) =>
  dayjs(date).fromNow ? dayjs(date).fromNow() : "";

export const firstDayOfWeek = (date: Date) => dayjs(date).weekday(0).toDate();

export const monthDiff = (a: Date, b: Date) => {
  let diff = (b.getFullYear() - a.getFullYear()) * 12;
  diff += b.getMonth() - a.getMonth();
  return diff;
};

export const isSameMonth = (a: Date, b: Date) => monthDiff(a, b) === 0;
