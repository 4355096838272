import { useMemo } from "react";
import { Activity, ActivityUser } from "../model";

export const useActivityUserById = (activity?: Activity) => {
  const usersById = useMemo(
    () =>
      activity?.users.reduce((acc: Record<string, ActivityUser>, user) => {
        const { userId } = user;
        return { ...acc, ...(userId ? { [userId]: user } : {}) };
      }, {}) ?? {},
    [activity]
  );

  return { getUserById: (userId?: string) => usersById[userId ?? ""] };
};
