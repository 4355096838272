import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { Activity } from "../../../model";
import { currencyList } from "../../../utils";
import { useTranslation } from "../../../hooks";
import { FormEvent, useState } from "react";
import { InlineIcon } from "../../InlineIcon";
import { Pencil, PlusLg, Trash3 } from "react-bootstrap-icons";

export interface EditActivityFormProps {
  isNew: boolean;
  activity: Activity;
  onSubmit: (activity: Activity) => void;
}

export const EditActivityForm: React.FC<EditActivityFormProps> = ({
  isNew,
  activity,
  onSubmit,
}) => {
  const [editedActivity, setEditedActivity] = useState(activity);
  const [newMemberName, setNewMemberName] = useState("");
  const { t } = useTranslation("Activities");

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(editedActivity);
  };

  const updateProperty = (property: keyof Activity, value: string) =>
    setEditedActivity({ ...editedActivity, [property]: value });

  const addUser = () => {
    setEditedActivity({
      ...editedActivity,
      users: [...editedActivity.users, { displayName: newMemberName }],
    });
    setNewMemberName("");
  };

  const removeUser = (index: number) => {
    setEditedActivity({
      ...editedActivity,
      users: [
        ...editedActivity.users.filter((_, userIndex) => userIndex !== index),
      ],
    });
  };

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>{t("Edit.Title")}</Form.Label>
        <Form.Control
          value={editedActivity.title}
          onChange={({ target }) => updateProperty("title", target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t("Edit.Description")}</Form.Label>
        <Form.Control
          value={editedActivity.description ?? ""}
          onChange={({ target }) => updateProperty("description", target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t("Edit.Currency")}</Form.Label>
        <Form.Select
          value={editedActivity.currency}
          onChange={({ target }) => updateProperty("currency", target.value)}
        >
          {currencyList.map((currency) => (
            <option key={currency.code} value={currency.code}>
              {currency.code} - {currency.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <div className="d-flex flex-column justify-content-stretch gap-3">
        <Form.Label>{t("Edit.Members")}</Form.Label>
        {editedActivity.users.map((activityUser, index) => (
          <InputGroup key={index}>
            <Form.Control
              type="text"
              disabled
              value={activityUser.displayName}
            />
            <Button
              disabled={
                activityUser.userId === editedActivity.createdBy ||
                activityUser.hasExpenses
              }
              onClick={() => removeUser(index)}
            >
              <InlineIcon icon={Trash3} spaceAfter="no" />
            </Button>
          </InputGroup>
        ))}
        <InputGroup>
          <Form.Control
            type="text"
            placeholder={t("Edit.MemberName")}
            value={newMemberName}
            onChange={({ target }) => setNewMemberName(target.value)}
          />
          <Button disabled={!newMemberName} onClick={addUser}>
            <InlineIcon icon={PlusLg} spaceAfter="no" />
          </Button>
        </InputGroup>
      </div>
      <div className="d-flex flex-row justify-content-end mt-3">
        <Button variant="primary" type="submit" className="col-12 col-sm-3">
          <InlineIcon icon={isNew ? PlusLg : Pencil} spaceAfter="lg" />
          {t(`Edit.${isNew ? "CreateActivityButton" : "EditActivityButton"}`)}
        </Button>
      </div>
    </Form>
  );
};
