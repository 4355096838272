import { useAuthenticatedFetch } from "./useAuthenticatedFetch";
import { Expense } from "../../model";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ACTIVITIES_KEY } from "./useActivitiesApi";
import { onOptimisticMutate } from "./optimisticMutation.utils";

export const EXPENSES_KEY = "expenses";

export const useGetExpenses = (activityId?: string) => {
  const { GET } = useAuthenticatedFetch<Expense[]>();
  return useQuery({
    queryKey: [EXPENSES_KEY, activityId],
    queryFn: () => GET(`/activities/${activityId}/expenses`),
    enabled: !!activityId,
  });
};

export const useSaveExpense = (activityId: string) => {
  const queryClient = useQueryClient();
  const { POST } = useAuthenticatedFetch<Expense, Expense>();
  return useMutation({
    mutationFn: (data: Expense) =>
      POST(`/activities/${activityId}/expenses`, { data }),
    ...onOptimisticMutate<Expense, Expense[]>(
      queryClient,
      [EXPENSES_KEY, activityId],
      (oldExpenses, expense) =>
        [
          ...(oldExpenses ?? []).filter((e) => e.id !== expense.id),
          expense,
        ].sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        )
    ),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_KEY] });
      queryClient.invalidateQueries({ queryKey: [EXPENSES_KEY, activityId] });
    },
  });
};

export const useDeleteExpense = (activityId: string) => {
  const queryClient = useQueryClient();
  const { DELETE } = useAuthenticatedFetch<void>();
  return useMutation({
    mutationFn: (expenseId: string) =>
      DELETE(`/activities/${activityId}/expenses/${expenseId}`),
    ...onOptimisticMutate<string, Expense[]>(
      queryClient,
      [EXPENSES_KEY, activityId],
      (oldExpenses, expenseId) => oldExpenses?.filter((e) => e.id !== expenseId)
    ),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_KEY] });
      queryClient.invalidateQueries({ queryKey: [EXPENSES_KEY, activityId] });
    },
  });
};
