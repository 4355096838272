import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useTranslation } from "../../hooks";
import { FormEvent, useEffect, useState } from "react";
import { InlineIcon } from "../InlineIcon";
import { ArrowLeft, ArrowRight, Clock, Funnel } from "react-bootstrap-icons";
import { ExpenseFilter } from "../../model";
import {
  getMonthRangeEnd,
  getMonthRangeStart,
  shiftMonthRange,
} from "../../utils";
import { MonthInput } from "../Date";

export interface ExpenseFilterPaneProps {
  open: boolean;
  onSubmit: (filter: ExpenseFilter) => void;
  onCancel: () => void;
  filter: ExpenseFilter;
}

export const ExpenseFilterPane: React.FC<ExpenseFilterPaneProps> = ({
  open,
  onSubmit,
  onCancel,
  filter,
}) => {
  const { t } = useTranslation("Expenses");
  const [currentFilter, setCurrentFilter] = useState<ExpenseFilter>({
    ...filter,
    users: filter.users.map((u) => ({ ...u })),
  });
  const { fromDate, toDate, users } = currentFilter;

  useEffect(() => {
    setCurrentFilter(filter);
  }, [filter]);

  const handleChangeRange = (direction: "before" | "after" | "now") => {
    const defaultDateRangeEnd = getMonthRangeEnd();
    const [startDate, endDate] =
      direction === "now"
        ? [getMonthRangeStart(defaultDateRangeEnd), defaultDateRangeEnd]
        : shiftMonthRange(fromDate, toDate, direction);

    setCurrentFilter({
      ...currentFilter,
      fromDate: startDate,
      toDate: endDate,
    });
  };

  const handleUpdateSelectedUser = (
    value: boolean,
    userId: string | undefined
  ) => {
    setCurrentFilter({
      ...currentFilter,
      users: currentFilter.users.map(({ selected, user }) => ({
        user,
        selected: user.userId === userId ? value : selected,
      })),
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(currentFilter);
  };

  return (
    <Offcanvas show={open} onHide={onCancel}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <InlineIcon icon={Funnel} spaceAfter="lg" />
          {t("Filters.Title")}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>{t("Filters.From")}</Form.Label>
            <MonthInput
              date={new Date(fromDate)}
              onChange={(date) =>
                setCurrentFilter({ ...currentFilter, fromDate: date })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("Filters.To")}</Form.Label>
            <MonthInput
              date={new Date(toDate)}
              onChange={(date) =>
                setCurrentFilter({ ...currentFilter, toDate: date })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <ButtonGroup className="col-12">
              <Button
                variant="outline-primary"
                className="col-4"
                onClick={() => handleChangeRange("before")}
              >
                <InlineIcon icon={ArrowLeft} spaceAfter="no" />
              </Button>
              <Button
                variant="outline-primary"
                className="col-4"
                onClick={() => handleChangeRange("now")}
              >
                <InlineIcon icon={Clock} spaceAfter="lg" />
                {t("Filters.Now")}
              </Button>
              <Button
                variant="outline-primary"
                className="col-4"
                onClick={() => handleChangeRange("after")}
              >
                <InlineIcon icon={ArrowRight} spaceAfter="no" />
              </Button>
            </ButtonGroup>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>{t("Filters.PaidBy")}</Form.Label>
            {users.map(({ selected, user }, index) => (
              <Form.Check
                key={user.userId ?? index}
                type="checkbox"
                id={user.userId}
                label={user.displayName}
                checked={selected}
                onChange={({ target }) =>
                  handleUpdateSelectedUser(target.checked, user.userId)
                }
              />
            ))}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>{t("Filters.Description")}</Form.Label>
            <Form.Control
              value={currentFilter.description}
              onChange={({ target }) =>
                setCurrentFilter({
                  ...currentFilter,
                  description: target.value,
                })
              }
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="col-12">
            {t("Filters.Apply")}
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
