import React, { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Expense } from "../../../model";
import { navigation } from "../../../config";
import { EditExpensePage } from "./EditExpensePage";
import { ActivityContext } from "../../../context";

export const EditExpense: React.FC<{}> = () => {
  const { activityId, activity, expenses, isLoadingExpenses } =
    useContext(ActivityContext);
  const { expenseId } = useParams<{ expenseId: string }>();
  const navigate = useNavigate();

  const editedExpense = useMemo<Expense | undefined>(() => {
    const expense = expenses?.find((expense) => expense.id === expenseId);
    return expense ? { ...expense } : undefined;
  }, [expenses, expenseId]);

  if (!editedExpense && !isLoadingExpenses) {
    navigate(navigation.expenses(activityId));
    return <></>;
  }

  return (
    <EditExpensePage
      activityId={activityId}
      activity={activity}
      expense={editedExpense}
    />
  );
};
