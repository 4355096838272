import React, { useContext, useEffect, useMemo, useRef } from "react";
import FormControl from "react-bootstrap/FormControl";
import Dropdown from "react-bootstrap/Dropdown";
import { useGetExpenseDescriptions } from "../../../hooks/api";
import { Spinner } from "react-bootstrap";
import { ActivityContext } from "../../../context";

export interface ExpenseDescriptionInputProps {
  autoFocus: boolean;
  value: string;
  onChange: (newValue: string) => void;
}

type DescriptionGroup = {
  description: string;
  occurrences: number;
};

const MAX_DESCRIPTIONS = 10;

export const ExpenseDescriptionInput: React.FC<
  ExpenseDescriptionInputProps
> = ({ autoFocus, value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>();

  const { activityId } = useContext(ActivityContext);
  const { data: descriptionGroups, isLoading } =
    useGetExpenseDescriptions(activityId);

  const filteredDescriptions = useMemo<DescriptionGroup[]>(() => {
    if (!descriptionGroups) {
      return [];
    }
    return Object.entries(descriptionGroups)
      .filter(([description]) =>
        description.toLowerCase().startsWith(value.toLowerCase())
      )
      .map(([description, occurrences]) => ({ description, occurrences }))
      .sort((a, b) => {
        const diff = b.occurrences - a.occurrences;
        return diff ? diff : a.description.localeCompare(b.description);
      })
      .slice(0, MAX_DESCRIPTIONS);
  }, [descriptionGroups, value]);

  const hasDescriptions = !!filteredDescriptions.length;

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  }, [autoFocus]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={FormControl}
        ref={inputRef}
        value={value}
        onChange={({ target }) => onChange((target as HTMLInputElement).value)}
      ></Dropdown.Toggle>
      <Dropdown.Menu className="col-12 col-md-6">
        {isLoading && (
          <div className="d-flex justify-content-center p-2">
            <Spinner animation="border" variant="primary" size="sm" />
          </div>
        )}
        {!isLoading && !hasDescriptions && (
          <Dropdown.Item disabled className="d-flex justify-content-between">
            <span></span>
            <span className="text-muted fst-italic">0</span>
          </Dropdown.Item>
        )}
        {!isLoading &&
          hasDescriptions &&
          filteredDescriptions?.map((group) => (
            <Dropdown.Item
              key={group.description}
              onClick={() => onChange(group.description)}
              className="d-flex justify-content-between"
            >
              <span>{group.description}</span>
              <span className="text-muted fst-italic">{group.occurrences}</span>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
