import { useMemo } from "react";
import { useUser } from "../../../hooks";
import { Activity } from "../../../model";
import { getDefaultCurrency } from "../../../utils";
import { EditActivityPage } from "./EditActivityPage";

export const CreateActivity: React.FC<{}> = () => {
  const user = useUser();

  const editedActivity = useMemo<Activity>(
    () => ({
      id: "",
      title: "",
      currency: getDefaultCurrency().code,
      users: [{ displayName: user.displayName, userId: user.id }],
      createdBy: user.id,
      createdOn: new Date().toISOString(),
      lastUpdatedOn: new Date().toISOString(),
    }),
    [user]
  );

  return <EditActivityPage isNew activity={editedActivity} />;
};
