import { useCallback } from "react";
import { getLocale, toMajorCurrencyUnit } from "../utils";

export const useAmountFormatter = (currency?: string) => {
  const formatAmount = useCallback(
    (amount: number) =>
      new Intl.NumberFormat(getLocale(), {
        style: "currency",
        currency,
      }).format(currency ? toMajorCurrencyUnit(amount, currency) : 0),
    [currency]
  );

  return { formatAmount };
};
