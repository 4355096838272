export const combinePath = (host: string, path: string) => {
  host = trimLast(host, "/");
  path = trimFirst(trimLast(path, "/"), "/");
  return `${host}/${path}`;
};

const trimFirst = (str: string, char: string) => {
  if (str.charAt(0) === char) {
    return str.substring(1, str.length);
  }
  return str;
};

const trimLast = (str: string, char: string) => {
  if (str.charAt(str.length - 1) === char) {
    return str.substring(0, str.length - 1);
  }
  return str;
};

export const constructUrl = (
  baseUrl: string,
  searchParams: { [key: string]: string }
) => {
  const url = new URL(baseUrl);
  Object.entries(searchParams).forEach((entry) =>
    url.searchParams.append(entry[0], entry[1])
  );

  return url.href;
};

export const getSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(searchParams.entries());
};
