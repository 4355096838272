import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { navigation } from "../../config";
import { useExportActivity, useImportActivity } from "../../hooks/api";
import { FormEvent, useContext, useEffect, useState } from "react";
import { InlineIcon } from "../InlineIcon";
import { BoxArrowInUpRight, BoxArrowUpRight } from "react-bootstrap-icons";
import { ExportProgress } from "./ExportProgress";
import { ActivityContext } from "../../context";
import { ActivityBreadcrumbs } from "../Navigation";

export const Export: React.FC<{}> = () => {
  const { activityId, activity } = useContext(ActivityContext);
  const navigate = useNavigate();
  const { t } = useTranslation("Export");

  const [importFrom, setImportFrom] = useState("");
  const [exportTo, setExportTo] = useState("");
  const [exportOverride, setExportOverride] = useState<boolean>(false);

  const { mutateAsync: exportActivity, isPending: isExporting } =
    useExportActivity(activityId, {
      exportTo,
      overrideExisting: exportOverride,
    });
  const { mutateAsync: importActivity, isPending: isImporting } =
    useImportActivity(activityId, importFrom);

  useEffect(() => {
    if (activity && !exportTo) {
      setExportTo(activity.title);
    }
  }, [activity, exportTo]);

  const handleImport = async (e: FormEvent) => {
    e.preventDefault();
    await importActivity();
    navigate(navigation.expenses(activityId));
  };

  const handleExport = async (e: FormEvent) => {
    e.preventDefault();
    await exportActivity();
    navigate(navigation.expenses(activityId));
  };

  return (
    <div className="d-flex flex-column gap-3">
      <ActivityBreadcrumbs activity={activity} pageTitle={t("Title")} />
      <Form noValidate onSubmit={handleImport}>
        <Form.Group className="mb-3">
          <Form.Label>{t("ImportFrom")}</Form.Label>
          <Form.Control
            placeholder="https://docs.com/spreadsheets/d/XXXX-XXX"
            value={importFrom}
            onChange={({ target }) => setImportFrom(target.value)}
          />
        </Form.Group>
        <div className="d-flex flex-row justify-content-end gap-3">
          <Button variant="primary" type="submit" className="col-12 col-sm-3">
            <InlineIcon icon={BoxArrowInUpRight} spaceAfter="lg" />
            {t("ImportButton")}
          </Button>
        </div>
      </Form>
      <Form noValidate onSubmit={handleExport}>
        <Form.Group className="mb-3">
          <Form.Label>{t("ExportTo")}</Form.Label>
          <Form.Control
            value={exportTo}
            onChange={({ target }) => setExportTo(target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label={t("ExportOverride")}
            checked={exportOverride}
            onChange={({ target }) => setExportOverride(target.checked)}
          />
        </Form.Group>
        <div className="d-flex flex-row justify-content-end gap-3">
          <Button variant="primary" type="submit" className="col-12 col-sm-3">
            <InlineIcon icon={BoxArrowUpRight} spaceAfter="lg" />
            {t("ExportButton")}
          </Button>
        </div>
      </Form>
      <ExportProgress
        type={isExporting ? "Export" : "Import"}
        inProgress={isExporting || isImporting}
      />
    </div>
  );
};
