import { useContext, useEffect } from "react";
import { getSearchParams } from "../utils";
import { useMergeUser } from "./api";
import { UserContext } from "../context";

export const useMergeGuestUser = () => {
  const { user } = useContext(UserContext);
  const { u: guestUserIdSearchParam } = getSearchParams();
  const savedGuestUserId = getGuestUserId();

  if (guestUserIdSearchParam && !savedGuestUserId) {
    saveGuestUserId(guestUserIdSearchParam);
  }

  const guestUserId = savedGuestUserId ?? guestUserIdSearchParam;
  const { isSuccess, isError, isPending, mutate } = useMergeUser();

  if (isSuccess || isError) {
    removeGuestUserId();
  }

  useEffect(() => {
    if (!!user && !!guestUserId) {
      mutate(guestUserId);
    }
  }, [guestUserId, mutate, user]);

  return { isMergingGuestUser: isPending };
};

const GUEST_USER_ID_KEY = "guest-user-id";

const saveGuestUserId = (guestUserId: string) =>
  sessionStorage.setItem(GUEST_USER_ID_KEY, guestUserId);

const removeGuestUserId = () => sessionStorage.removeItem(GUEST_USER_ID_KEY);

const getGuestUserId = () => sessionStorage.getItem(GUEST_USER_ID_KEY);
