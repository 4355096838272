import { PropsWithChildren, useContext, useEffect } from "react";
import { Login } from "./Login";
import { UserContext } from "../../context";
import { useMergeGuestUser } from "../../hooks";
import { useReAuthenticate } from "../../hooks/api";
import { Wait } from "./Wait";

export interface ProtectedRouteProps extends PropsWithChildren {
  loggingOut: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user, setUser } = useContext(UserContext);
  const { data: refreshedUser, isError } = useReAuthenticate(!user);

  useEffect(() => {
    if (!user && refreshedUser) {
      setUser(refreshedUser);
    }
  }, [refreshedUser, user, setUser]);

  const { isMergingGuestUser } = useMergeGuestUser();

  if (isError) {
    return <Login isError={false} />;
  }

  if (!user || isMergingGuestUser) {
    return <Wait />;
  }

  return <>{children}</>;
};
