import { useAuthenticatedFetch } from "./useAuthenticatedFetch";
import { useQuery } from "@tanstack/react-query";

export const EXPENSE_DESCRIPTIONS_KEY = "expenseDescriptions";

export const useGetExpenseDescriptions = (activityId: string) => {
  const { GET } = useAuthenticatedFetch<Record<string, number>>();
  return useQuery({
    queryKey: [EXPENSE_DESCRIPTIONS_KEY, activityId],
    queryFn: () => GET(`/activities/${activityId}/expenses/descriptions`),
    enabled: !!activityId,
  });
};
