import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import { PlusLg } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { navigation } from "../../config";
import { useActivityUserById, useTranslation } from "../../hooks";
import { useDeleteActivity, useSetDefaultActivity } from "../../hooks/api";
import { ActivityPanel, ExpensesPanel } from "./";
import { ConfirmationModal, InlineIcon } from "../";
import { ActivityContext } from "../../context";
import { ActivityBreadcrumbs } from "../Navigation";

export const Expenses: React.FC<{}> = () => {
  const {
    activityId,
    activity,
    isLoadingActivity,
    expenses,
    isLoadingExpenses,
  } = useContext(ActivityContext);
  const { getUserById } = useActivityUserById(activity);
  const { mutateAsync: deleteActivity } = useDeleteActivity(activityId);
  const { mutateAsync: setDefaultActivity } = useSetDefaultActivity(activityId);
  const { t } = useTranslation("Expenses");
  const navigate = useNavigate();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleSetDefaultActivity = (isDefault: boolean) =>
    setDefaultActivity(isDefault);

  const handleEditActivity = () => navigate(navigation.activity(activityId));

  const handleDeleteActivity = () => setShowDeleteConfirm(true);

  const handleConfirmDeleteActivity = (confirm: boolean) => {
    if (confirm) {
      deleteActivity();
      navigate(navigation.activities);
    }
    setShowDeleteConfirm(false);
  };

  const handleExportClick = () => navigate(navigation.export(activityId));

  const handleExpenseClick = (expenseId?: string) =>
    expenseId && navigate(navigation.expense(activityId, expenseId));

  const handleAddExpenseClick = () =>
    navigate(navigation.newExpense(activityId));

  return (
    <div className="d-flex flex-column gap-3">
      <ConfirmationModal
        title={t("ConfirmDeleteActivity.Title")}
        description={`Are you sure you want to delete the activity ${activity?.title}?`} // TODO translation
        open={showDeleteConfirm}
        onClose={handleConfirmDeleteActivity}
      />
      <ActivityBreadcrumbs />
      <ActivityPanel
        activity={activity}
        onSetDefault={handleSetDefaultActivity}
        onEdit={handleEditActivity}
        onDelete={handleDeleteActivity}
        onExport={handleExportClick}
      />
      <div className="d-flex flex-row gap-3 justify-content-end">
        <Button onClick={handleAddExpenseClick} disabled={isLoadingActivity}>
          <InlineIcon icon={PlusLg} spaceAfter="lg" />
          {t("Actions.AddExpense")}
        </Button>
      </div>
      {(isLoadingExpenses || (expenses?.length ?? 0) > 0) && (
        <ExpensesPanel
          expenses={expenses ?? []}
          isLoading={isLoadingExpenses}
          userById={getUserById}
          onExpenseClick={handleExpenseClick}
        />
      )}
    </div>
  );
};
