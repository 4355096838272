import { HttpMethod, fetch } from "./";
import { GetAccessTokenRequest, User } from "../model";
import { constructUrl, getSearchParams } from "../utils";

export type OAuth2Config = {
  authorizeUrl: string;
  clientId: string;
  redirectUri: string;
  scope: string[];
  authenticateEndpoint: string;
};

const VALID_CHARS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
const OAUTH_STATE_KEY = "oauth2-state-key";

export const authorize = async ({
  authorizeUrl,
  clientId,
  redirectUri,
  scope,
}: OAuth2Config) => {
  const state = generateState();
  saveState(state);

  window.location.href = constructUrl(authorizeUrl, {
    response_type: "code",
    client_id: clientId,
    redirect_uri: redirectUri,
    scope: scope.join(" "),
    state,
    access_type: "offline", // Google refresh token
    prompt: "consent", // https://github.com/googleapis/google-api-python-client/issues/213
  });
};

export const getAuthenticatedUser = async ({
  redirectUri,
  authenticateEndpoint,
}: OAuth2Config) => {
  const payload = getSearchParams();
  const state = payload && payload.state;
  const error = payload && payload.error;

  if (error) {
    throw new Error(decodeURI(error) || "Unknown error");
  } else if (!state || !checkState(state)) {
    throw new Error("OAuth error: State mismatch");
  }

  const code = payload.code;

  const response = await authenticateUser(
    { redirectUri, code },
    authenticateEndpoint
  );
  removeState();

  return response;
};

const generateState = () => {
  let randomArray = new Uint8Array(40);
  window.crypto.getRandomValues(randomArray);
  randomArray = randomArray.map(
    (x: number) => VALID_CHARS.charCodeAt(x % VALID_CHARS.length) ?? 0
  );
  const randomState = String.fromCharCode(...Array.from(randomArray));
  return randomState;
};

const saveState = (state: string) => {
  sessionStorage.setItem(OAUTH_STATE_KEY, state);
};

const removeState = () => {
  sessionStorage.removeItem(OAUTH_STATE_KEY);
};

const checkState = (receivedState: string) => {
  const state = sessionStorage.getItem(OAUTH_STATE_KEY);
  return state === receivedState;
};

const authenticateUser = async (
  request: GetAccessTokenRequest,
  endpoint: string
): Promise<User> => {
  return await fetch(endpoint, HttpMethod.Post, { data: request });
};
