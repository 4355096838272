import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Placeholder from "react-bootstrap/Placeholder";
import { Activity, Feature } from "../../model";
import { combinePath, currencyByCode } from "../../utils";
import { useAmountFormatter, useTranslation, useUser } from "../../hooks";
import { InlineIcon } from "../InlineIcon";
import {
  BoxArrowUpRight,
  Calculator,
  Pencil,
  PieChart,
  Pin,
  PinAngle,
  Share,
  Trash3,
} from "react-bootstrap-icons";

export interface ActivityPanelProps {
  activity: Activity | undefined;
  onSetDefault: (isDefault: boolean) => void;
  onEdit: () => void;
  onDelete: () => void;
  onExport: () => void;
}

export const ActivityPanel: React.FC<ActivityPanelProps> = ({
  activity,
  onSetDefault,
  onEdit,
  onDelete,
  onExport,
}) => {
  const user = useUser();
  const { t } = useTranslation("Expenses");
  // Mode is either balance or total per user
  const [balanceMode, setBalanceMode] = useState<boolean>(true);
  const { formatAmount } = useAmountFormatter(activity?.currency);

  const updateBalanceMode = () => setBalanceMode((oldMode) => !oldMode);

  const exportEnabled = user.features.includes(Feature.export);

  if (!activity) {
    return (
      <Card>
        <Card.Header>{t("ActivityCard.Title")}</Card.Header>
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={4} />
          </Placeholder>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={7} />
          </Placeholder>
        </Card.Body>
      </Card>
    );
  }

  const displayCurrency = () => {
    if (!activity) {
      return "";
    }
    const currency = currencyByCode[activity.currency];
    return `${currency.code} - ${currency.name}`;
  };

  const balanceColor = (balance: number) =>
    balance >= 0 ? "text-success" : "text-danger";

  const handleShareClick = (activityUserId?: string) => {
    if (activityUserId) {
      // TODO translation
      navigator.share({
        title: "Sam Comptes",
        text: `${user.displayName} requests you to join the activity "${activity.title}" on Sam Comptes!`,
        url: combinePath(
          new URL(window.location.href).origin,
          `?u=${activityUserId}`
        ),
      });
    }
  };

  return (
    <Card>
      <Card.Header>{t("ActivityCard.Title")}</Card.Header>
      <Card.Body>
        <Card.Title>{activity.title}</Card.Title>
        <Card.Text>{activity.description}</Card.Text>
      </Card.Body>
      <ListGroup variant="flush">
        <ListGroup.Item key="0">
          <ButtonGroup>
            <Button
              variant="outline-primary"
              onClick={() => onSetDefault(!activity.isDefault)}
            >
              <InlineIcon
                icon={activity.isDefault ? Pin : PinAngle}
                spaceAfter="no"
              />
            </Button>
            <Button variant="outline-primary" onClick={onEdit}>
              <InlineIcon icon={Pencil} spaceAfter="no" />
            </Button>
            <Button variant="outline-secondary" onClick={onDelete}>
              <InlineIcon icon={Trash3} spaceAfter="no" />
            </Button>
            {exportEnabled && (
              <Button variant="outline-secondary" onClick={onExport}>
                <InlineIcon icon={BoxArrowUpRight} spaceAfter="no" />
              </Button>
            )}
            <Button variant="outline-secondary" onClick={updateBalanceMode}>
              <InlineIcon
                icon={balanceMode ? PieChart : Calculator}
                spaceAfter="no"
              />
            </Button>
          </ButtonGroup>
        </ListGroup.Item>
        <ListGroup.Item
          key="1"
          className="d-flex flex-row justify-content-stretch gap-2 align-items-center"
        >
          <span className="flex-grow-1">{displayCurrency()}</span>
          <span>
            {!balanceMode && formatAmount(activity.totalExpenses ?? 0)}
          </span>
        </ListGroup.Item>
        {activity.users.map((activityUser, index) => (
          <ListGroup.Item
            key={activityUser.userId ?? index}
            className="d-flex flex-row justify-content-stretch gap-2 align-items-center"
          >
            <Button
              variant="outline-secondary"
              onClick={() => handleShareClick(activityUser.userId)}
              disabled={!activityUser.isGuest}
            >
              <InlineIcon icon={Share} spaceAfter="no" />
            </Button>
            <span className="flex-grow-1">{activityUser.displayName}</span>
            <span className={balanceColor(activityUser.balance ?? 0)}>
              {formatAmount(
                (balanceMode
                  ? activityUser.balance
                  : activityUser.totalExpenses) ?? 0
              )}
            </span>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card>
  );
};
