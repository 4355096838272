import { useCallback } from "react";

// TODO externalize json translation and add support for fr, etc.

const en = {
  Common: {
    ConfirmationModal: {
      Cancel: "Cancel",
      Ok: "Ok",
    },
    DatePicker: {
      Yesterday: "Yesterday",
    },
  },
  Authentication: {
    Login: {
      ApplicationTitle: "Sam Comptes",
      SignInMicrosoft: "Sign in with Microsoft",
      SignInGoogle: "Sign in with Google",
      SignInFacebook: "Sign in with Facebook",
    },
    PostLogin: {
      Error: {
        Title: "Unable to sign you in",
        Description: "We can't sign you in for now, please try again.",
      },
    },
  },
  Navigation: {
    ApplicationTitle: "Sam Comptes",
    Activities: "Activities",
    SignOut: "Sign out",
  },
  Activities: {
    Title: "Activities",
    Actions: {
      CreateActivity: "Create activity",
    },
    Edit: {
      CreateActivity: "Create a new activity",
      EditActivity: "Edit an activity",
      Title: "Title",
      Description: "Description",
      Currency: "Currency",
      Members: "Members",
      MemberName: "Member name",
      CreateActivityButton: "Create",
      EditActivityButton: "Update",
    },
  },
  Expenses: {
    Title: "Expenses",
    ActivityCard: {
      Title: "Activity",
    },
    Actions: {
      AddExpense: "Add expense",
    },
    Filters: {
      Title: "Filter",
      From: "From",
      To: "To",
      Now: "Now",
      PaidBy: "Paid by",
      Description: "Description contains",
      Apply: "Apply",
    },
    ConfirmDeleteActivity: {
      Title: "Delete Activity",
    },
    ConfirmDeleteExpense: {
      Title: "Delete Expense",
    },
    Edit: {
      CreateExpense: "Add a new expense",
      EditExpense: "Edit an expense",
      Description: "Description",
      Amount: "Amount",
      Date: "Date",
      PaidBy: "Paid by",
      CreateButton: "Create",
      UpdateButton: "Update",
      DeleteButton: "Delete",
    },
  },
  Export: {
    Title: "Export activity",
    ImportFrom: "Import from",
    ImportButton: "Import",
    ExportTo: "Export to",
    ExportOverride: "Override existing",
    ExportButton: "Export",
    Importing: "Importing...",
    Exporting: "Exporting...",
  },
};

export const useTranslation = (domain: string) => {
  const translate = useCallback(
    (key: string) => getTranslation(en, `${domain}.${key}`),
    [domain]
  );

  return { t: translate };
};

const getTranslation = (translations: any, path: string): string => {
  const keys = path.split(".");

  return (
    keys.reduce((acc, key) => {
      if (acc && typeof acc === "object" && key in acc) {
        return acc[key];
      } else {
        return undefined;
      }
    }, translations) ?? path
  );
};
