import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { navigation } from "../../config";
import { HouseDoor } from "react-bootstrap-icons";
import { InlineIcon } from "../InlineIcon";
import { Activity } from "../../model";

export interface ActivityBreadcrumbsProps {
  activity?: Pick<Activity, "id" | "title">;
  pageTitle?: string;
}

export const ActivityBreadcrumbs: React.FC<ActivityBreadcrumbsProps> = ({
  activity,
  pageTitle,
}) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: navigation.activities }}>
        <InlineIcon icon={HouseDoor} spaceAfter="no" />
      </Breadcrumb.Item>
      {activity && (
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: navigation.expenses(activity.id) }}
        >
          {activity.title}
        </Breadcrumb.Item>
      )}
      {pageTitle && <Breadcrumb.Item active>{pageTitle}</Breadcrumb.Item>}
    </Breadcrumb>
  );
};
