import React, { useContext, useMemo } from "react";
import { useUser } from "../../../hooks";
import { Expense } from "../../../model";
import { getDefaultCurrency } from "../../../utils";
import { EditExpensePage } from "./EditExpensePage";
import { ActivityContext } from "../../../context";

export const CreateExpense: React.FC<{}> = () => {
  const { activityId, activity } = useContext(ActivityContext);
  const user = useUser();

  const editedExpense = useMemo<Expense>(
    () => ({
      id: "",
      userId: user.id,
      amount: 0,
      date: new Date().toISOString(),
      description: "",
      currency: activity?.currency ?? getDefaultCurrency().code,
    }),
    [user, activity]
  );

  return (
    <EditExpensePage
      activityId={activityId}
      activity={activity}
      expense={editedExpense}
      isNew
    />
  );
};
