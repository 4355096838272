import { useCallback, useEffect, useMemo, useState } from "react";
import { Activity, ActivityUser, Expense, ExpenseFilter } from "../model";
import { getMonthRangeEnd, getMonthRangeStart } from "../utils";

export const useExpenseFilter = (
  activity: Activity | undefined,
  expenses: Expense[]
) => {
  const defaultToDate = useMemo(
    () => getMonthRangeEnd(new Date(expenses[0]?.date ?? new Date())),
    [expenses]
  );

  const defaultFromDate = useMemo(
    () =>
      getMonthRangeStart(
        defaultToDate,
        new Date(expenses[(expenses?.length ?? 1) - 1]?.date ?? new Date())
      ),
    [expenses, defaultToDate]
  );

  const getDefaultFilter = useCallback(
    (activityUsers?: ActivityUser[]): ExpenseFilter => ({
      fromDate: defaultFromDate,
      toDate: defaultToDate,
      users: activityUsers
        ? activityUsers.map((user) => ({ selected: true, user }))
        : [],
      description: "",
    }),
    [defaultFromDate, defaultToDate]
  );

  const [filter, setFilter] = useState<ExpenseFilter>(() =>
    getDefaultFilter(activity?.users)
  );

  useEffect(() => {
    if (activity?.users) {
      setFilter(getDefaultFilter(activity.users));
    }
  }, [activity?.users, getDefaultFilter]);

  const filteredExpenses = useMemo(() => {
    const selectedUsers = filter.users
      .filter((u) => u.selected)
      .map((u) => u.user.userId);

    return expenses.filter((expense) => {
      const expenseDate = new Date(expense.date);
      return (
        selectedUsers.includes(expense.userId) &&
        expenseDate >= filter.fromDate &&
        expenseDate <= filter.toDate &&
        expense.description
          ?.toLocaleLowerCase()
          .includes(filter.description.toLocaleLowerCase())
      );
    });
  }, [expenses, filter]);

  return { filter, setFilter, filteredExpenses };
};
