import { useContext } from "react";
import { UserContext } from "../context";

export const useUser = () => {
  const { user } = useContext(UserContext);

  if (!user) {
    throw new Error("User is not defined");
  }

  return user;
};
