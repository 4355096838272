import React, { PropsWithChildren, createContext, useState } from "react";
import { User } from "../model";

export interface UserContextValue {
  user: User | undefined;
  setUser: (user: User) => void;
  unsetUser: () => void;
}

export const UserContext = createContext<UserContextValue>({
  user: undefined,
  setUser: () => undefined,
  unsetUser: () => Promise.resolve(),
});

export const UserProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren) => {
  const [user, setUser] = useState<User>();
  const userContextValue: UserContextValue = {
    user,
    setUser,
    unsetUser: () => setUser(undefined),
  };

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};
