import React, { createContext, PropsWithChildren } from "react";
import { Activity } from "../model";
import { useGetActivities } from "../hooks/api";

export interface ActivitiesContextValue {
  activities: Activity[];
  isLoadingActivities: boolean;
}

export const ActivitiesContext = createContext<ActivitiesContextValue>({
  activities: [],
  isLoadingActivities: false,
});

export const ActivitiesProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { data: activities, isLoading: isLoadingActivities } =
    useGetActivities();

  return (
    <ActivitiesContext.Provider
      value={{
        activities: activities ?? [],
        isLoadingActivities,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
