export interface User {
  id: string;
  displayName: string;
  accessToken: string;
  features: Feature[];
}

export enum Feature {
  import = "import",
  export = "export",
}
