import React, { useMemo } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Placeholder from "react-bootstrap/Placeholder";
import { Activity } from "../../model";
import { useNavigate } from "react-router-dom";
import { navigation } from "../../config";
import { useUser } from "../../hooks";
import { ago, getLocale, toMajorCurrencyUnit } from "../../utils";
import { InlineIcon } from "../InlineIcon";
import { Pin } from "react-bootstrap-icons";

export interface ActivityListProps {
  activities: Activity[];
  isLoading: boolean;
}

export const ActivityList: React.FC<ActivityListProps> = ({
  activities,
  isLoading,
}) => {
  const user = useUser();
  const navigate = useNavigate();

  const sortedActivities = useMemo(
    () => [
      ...activities.sort((a, b) =>
        a.isDefault
          ? -1
          : b.isDefault
          ? 1
          : new Date(b.lastExpenseDate ?? b.lastUpdatedOn).getTime() -
            new Date(a.lastExpenseDate ?? a.lastUpdatedOn).getTime()
      ),
    ],
    [activities]
  );

  const toExpenses = (activityId: string) =>
    navigate(navigation.expenses(activityId));

  const getBalance = (activity: Activity) =>
    activity.users.find((activityUser) => activityUser.userId === user?.id)
      ?.balance ?? 0;

  const balanceColor = (balance: number) =>
    balance >= 0 ? "text-success" : "text-danger";

  if (isLoading) {
    return (
      <ListGroup>
        <ListGroup.Item className="d-flex flex-column align-items-stretch gap-2">
          <Placeholder as="p" animation="glow">
            <Placeholder xs={6} />
            <Placeholder xs={8} size="sm" /> <Placeholder xs={2} size="sm" />
            <Placeholder xs={3} size="sm" />
          </Placeholder>
        </ListGroup.Item>
      </ListGroup>
    );
  }

  return (
    <ListGroup>
      {sortedActivities?.map((activity) => (
        <ListGroup.Item
          action
          key={activity.id}
          className="d-flex flex-column align-items-stretch"
          onClick={() => toExpenses(activity.id)}
        >
          <span>
            {activity.isDefault && <InlineIcon icon={Pin} spaceAfter="lg" />}
            {activity.title}
          </span>
          {activity.description && (
            <small className="text-muted">{activity.description}</small>
          )}
          <small
            className={`align-self-end ${balanceColor(getBalance(activity))}`}
          >
            {new Intl.NumberFormat(getLocale(), {
              style: "currency",
              currency: activity.currency,
            }).format(
              toMajorCurrencyUnit(getBalance(activity), activity.currency)
            )}
          </small>
          <small className="text-muted align-self-end">
            {ago(new Date(activity.lastUpdatedOn))}
          </small>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};
