import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { ActivityUser, Expense } from "../../../model";
import { FormEvent, useState } from "react";
import { useTranslation } from "../../../hooks";
import {
  currencyList,
  formatAmountValue,
  parseAmountValue,
  sanitizeAmountValue,
  toMajorCurrencyUnit,
  toMinorCurrencyUnit,
} from "../../../utils";
import { ConfirmationModal, InlineIcon } from "../../";
import { Pencil, PlusLg, Trash3 } from "react-bootstrap-icons";
import { DatePicker } from "../../Date/DatePicker";
import { ExpenseDescriptionInput } from "./ExpenseDescriptionInput";

export interface EditExpenseFormProps {
  isNew?: boolean;
  users: ActivityUser[];
  expense: Expense;
  onSubmit: (expense: Expense) => void;
  onDelete: (expense: Expense) => void;
}

export const EditExpenseForm: React.FC<EditExpenseFormProps> = ({
  isNew,
  users,
  expense,
  onSubmit,
  onDelete,
}) => {
  const [editedExpense, setEditedExpense] = useState(expense);
  const { t } = useTranslation("Expenses");
  const [amount, setAmount] = useState(() =>
    formatAmountValue(
      toMajorCurrencyUnit(editedExpense.amount, editedExpense.currency)
    )
  );
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit({
      ...editedExpense,
      amount: toMinorCurrencyUnit(
        parseAmountValue(amount),
        editedExpense.currency
      ),
    });
  };

  const handleDelete = () => setShowDeleteConfirm(true);

  const handleConfirmDelete = (confirm: boolean) => {
    if (confirm) {
      onDelete(editedExpense);
    }
    setShowDeleteConfirm(false);
  };

  const updateProperty = (
    property: keyof Expense,
    value: string | number | Date
  ) => setEditedExpense({ ...editedExpense, [property]: value });

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>{t("Edit.Description")}</Form.Label>
        <ExpenseDescriptionInput
          autoFocus
          value={editedExpense.description ?? ""}
          onChange={(value) => updateProperty("description", value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t("Edit.Amount")}</Form.Label>
        <InputGroup>
          <Form.Control
            type="text"
            inputMode="numeric"
            value={amount}
            onChange={({ target }) =>
              setAmount(sanitizeAmountValue(target.value))
            }
          />
          <Form.Select
            value={editedExpense.currency}
            onChange={({ target }) => updateProperty("currency", target.value)}
          >
            {currencyList.map((currency) => (
              <option key={currency.code} value={currency.code}>
                {currency.code} - {currency.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t("Edit.Date")}</Form.Label>
        <DatePicker
          value={new Date(editedExpense.date)}
          onChange={(date) => updateProperty("date", date)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t("Edit.PaidBy")}</Form.Label>
        <Form.Select
          value={editedExpense.userId}
          onChange={({ target }) => updateProperty("userId", target.value)}
        >
          {users.map((activityUser) => (
            <option key={activityUser.userId} value={activityUser.userId}>
              {activityUser.displayName}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <div className="d-flex flex-row justify-content-end gap-3">
        <ButtonGroup
          className={`mt-3 col-12 ${isNew ? "col-sm-3" : "col-sm-6"}`}
        >
          <Button variant="primary" type="submit">
            <InlineIcon icon={isNew ? PlusLg : Pencil} spaceAfter="lg" />
            {t(`Edit.${isNew ? "CreateButton" : "UpdateButton"}`)}
          </Button>
          {!isNew && (
            <Button variant="outline-secondary" onClick={handleDelete}>
              <InlineIcon icon={Trash3} spaceAfter="lg" />
              {t("Edit.DeleteButton")}
            </Button>
          )}
        </ButtonGroup>
      </div>
      <ConfirmationModal
        title={t("ConfirmDeleteExpense.Title")}
        description={`Are you sure you want to delete expense ${editedExpense.description}?`}
        open={showDeleteConfirm}
        onClose={handleConfirmDelete}
        okLabel={t("Edit.DeleteButton")}
      />
    </Form>
  );
};
