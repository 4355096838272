import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import { Activity, Expense } from "../model";
import { useNavigate, useParams } from "react-router-dom";
import { useGetExpenses } from "../hooks/api";
import { ActivitiesContext } from "./ActivitiesContext";
import { navigation } from "../config";

export interface ActivityContextValue {
  activityId: string;
  activity?: Activity;
  isLoadingActivity: boolean;
  expenses: Expense[];
  isLoadingExpenses: boolean;
}

export const ActivityContext = createContext<ActivityContextValue>({
  activityId: "",
  isLoadingActivity: false,
  expenses: [],
  isLoadingExpenses: false,
});

export const ActivityProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { activityId } = useParams<{ activityId: string }>();
  const { activities, isLoadingActivities } = useContext(ActivitiesContext);
  const { data: expenses, isLoading: isLoadingExpenses } =
    useGetExpenses(activityId);
  const navigate = useNavigate();

  const activity = useMemo(
    () => activities.find((a) => a.id === (activityId ?? "")),
    [activityId, activities]
  );

  if (!activityId) {
    throw new Error("activityId param not set");
  }

  if (!isLoadingActivities && !activity) {
    navigate(navigation.activities);
    return <></>;
  }

  return (
    <ActivityContext.Provider
      value={{
        activityId,
        activity,
        isLoadingActivity: isLoadingActivities,
        expenses: expenses ?? [],
        isLoadingExpenses: isLoadingExpenses,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};
